export default class DateTimeDisplay {
  constructor() {
    this.initializeDateAndTime();
  }

  initializeDateAndTime() {
    const dateAndTimeNodes = document.getElementsByClassName('date-and-time-js');
    if(dateAndTimeNodes.length === 0) { return; }

    this.update(dateAndTimeNodes);
    window.setInterval(() => {
      this.update(dateAndTimeNodes);
    }, 1000);
  }

  update(nodes) {
    for(let node of nodes) {
      node.innerHTML = moment().format('MMM/DD/YYYY  hh:mm a');
    }
  }
}
