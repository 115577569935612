export default class ImageComponent {
  constructor(container) {
    this.initialize(container) && this.addEventListeners();
  }

  initialize(container) {
    this.image = container.getElementsByClassName('js-avatar-file-img')[0];
    this.deleteImageButton = container.getElementsByClassName('js-avatar-file-destroy')[0];
    this.fileInput = container.getElementsByClassName('js-file-input')[0];
    this.previewNode = container.getElementsByClassName('js-avatar-file-preview')[0];

    if(!(this.image && this.deleteImageButton && this.fileInput && this.previewNode)) { return false; }

    this.originalImageURL = this.image.getAttribute('src');
    this.imageName = 'profile.png';
    this.uploadedImageURL;

    return true;
  }

  addEventListeners() {
    this.fileInput.addEventListener('click', this.saveImage.bind(this));
  }

  saveImage(e) {
    e.preventDefault() && e.stopImmediatePropagation();
  }
}
