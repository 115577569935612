
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 // Utils
import * as Utils from './utils';
window.Utils = Utils;

require('./bootstrap');
require('slick-carousel/slick/slick.js');
import './initializers/moment-config';

// Components
import DateTimeDisplay from './components/date-time-display';
import ImageUploader from './components/image-uploader';
import MobileMenu from './components/mobile-menu';

document.addEventListener('DOMContentLoaded', function () {
  new DateTimeDisplay();
  new ImageUploader();
  new MobileMenu();
});
