import ImageComponent from './image-component';

export default class ImageUploader {
  constructor() {
    this.initialize();
  }

  initialize() {
    const avatarComponents = document.getElementsByClassName('js-avatar-file');

    for(let component of avatarComponents) {
      new ImageComponent(component);
    }
  }
}
