import moment from 'moment';
window.moment = moment;

window.moment.defineLocale('es-mx', {
  parentLocale: 'es',
  monthsShort: [
    'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
    'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
  ]
});
