export default class MobileMenu {
  constructor() {
    this.initializeMobileMenu();
  }

  initializeMobileMenu() {
    var hamburger = document.getElementById('hamburger-icon');
    var layout = document.getElementsByClassName("l-base")[0];
    var overlay = document.getElementsByClassName("m-overlay")[0];
    var header = document.getElementsByClassName('m-header')[0];

    if (!Utils.isPresent(hamburger, overlay, header)) { return; }

    hamburger.addEventListener('click', function() {
      overlay.classList.toggle('active');
      header.classList.toggle('active');
      layout.classList.toggle('fixed');
    });
  }
}
